
// .banner{
//   background-size:100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding: 139px 111px;
//   border-radius: 15px;   
// }

.mainImage{
  width: 100%;
  height: 370px;
  object-fit: cover
}

@media (max-width: 1025px){
    .mainImage{
        // width: 450px;
        height: 459px;
    }
  }

  @media (max-width: 768px) {
    .mainImage{
        // width: 400px;
        height: 459px;
    }  
  }
  
  @media (max-width: 539px) {
    .mainImage{
        width: 350px;
        height: 100%;
    }
    .banner{
        // background-position: right !important;
    }  
  }
  
