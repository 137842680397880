
// .banner{
//   background-size:100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding: 139px 111px;
//   border-radius: 15px;   
// }

@media (max-width: 1025px){
    .mainImage{
        width: 450px;
    }
  }

  @media (max-width: 768px) {
    .mainImage{
        width: 400px;
    }  
  }
  
  @media (max-width: 539px) {
    .mainImage{
        width: 350px;
    }
    .banner{
        // background-position: right !important;
    }  
  }
  
