body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5 !important;
  color:#1D3869 !important;
  padding: 0 !important;
}


a{
  text-decoration: none;
  color:#1D3869;
}

a:hover{
  text-decoration: underline !important;
  color: #d32f2f !important;  
}

.fade-in-animation {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  transition: opacity 0.5s ease-in-out;
}



@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes backgroundColorChange {
  from {
      background-color: rgb(255, 255, 255);
  }
  to {
      background-color: #1D3869;
  }
}

.backgroundColorTransition {
  animation: backgroundColorChange 0.8s ease-in-out forwards;
}

@media (max-width: 1025px) {
  .logo{
    width: 190px;
  }
}

@media (max-width: 768px) {
  .logo{
    width: 170px;
  }
}

@media (max-width: 768px) {
  .logo{
    width: 190px;
  }
}


