.root {
  background-color: #F5F5F5;
  padding: 10px 0;
  // border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.header {
  background-color: #f5f5f594 !important;
  color: inherit;
  // position: fixed;
  // top:0px;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; 
}

.buttons {
  button {
    margin-left: 10px;    
  }

  a {
    text-decoration: none;
  }
}


.p{
  font-size: 22px;
  font-weight: 700 !important;
  text-transform: uppercase;  
  margin-left: 10px !important;
}
.subp{
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: none;  
  margin-left: 10px !important;
}


